@import "normalize.scss";

html,
body {
  // min-height: 100vh;
  padding: 0;
  margin: 0;
  position: relative;
  background: #f4f4f4;
  background-color: var(--bodyBg);
  color: #222;
  color: var(--content);
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  transition: all 0.5s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --cyan: #00a2d9;
  --berry: #b7295a;
  --green: #7ab800;
  --yellow: #f2af00;
  --black: #202020;
  --paleGray: #eee;
  --almostWhite: #f4f4f4;
  --cardLarge: 150px;
  --cardSmall: 140px;
}

.lightMode {
  --bodyBg: var(--cyan);
  --containerBg: #fff;
  --addButton: var(--green);
  --content: var(--black);
  --settingOverlayBg: #fff;
  --settingsContent: var(--black);
  --navBg: #004f6a;
  --overlayHeading: var(--black);
  --formFieldBg: var(--paleGray);
  --formLabelText: #999;
  --saveButton: var(--green);
  --cancelButton: var(--green);
  --removeButton: var(--berry);
  --removeCancelButton: var(--berry);
  --checkIconColor: var(--cyan);
  --checkIconLabel: var(--black);
  --uncheckedIconLabel: #777;
  --missingField: var(--berry);
  --disabledButtonBg: var(--paleGray);
  --disabledButtonColor: #999;
  --modalSubsectionBg: #fff1f6;
  --modalSubsectionBorder: var(--berry);
  --modalSubsectionText: #333;
  --shoppingListBg: #fff;
  --shoppingListBorder: #e6e6e6;
}

.darkMode {
  --bodyBg: #000;
  --containerBg: #1b1b1b;
  --addButton: #3f3f3f;
  --content: var(--almostWhite);
  --settingOverlayBg: #1b1b1b;
  --settingsContent: var(--almostWhite);
  --navBg: #3f3f3f;
  --overlayHeading: var(--almostWhite);
  --formFieldBg: #333;
  --formLabelText: #999;
  --saveButton: var(--green);
  --cancelButton: var(--almostWhite);
  --removeButton: var(--berry);
  --removeCancelButton: var(--almostWhite);
  --checkIconColor: var(--cyan);
  --checkIconLabel: var(--almostWhite);
  --uncheckedIconLabel: #777;
  --missingField: var(--yellow);
  --disabledButtonBg: var(--paleGray);
  --disabledButtonColor: #999;
  --modalSubsectionBg: #333;
  --modalSubsectionBorder: var(--yellow);
  --modalSubsectionText: var(--yellow);
  --shoppingListBg: #333;
  --shoppingListBorder: #444;
}

#root {
  border: 1px solid transparent;
  position: relative;
}

.noscroll {
  overflow: hidden;
}